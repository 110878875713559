import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const frontmatter = {
  body: 'Noses',
  slug: '/noses/',
  title: 'Noses',
}

export const query = graphql`
  query NosesPageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: "noses.jpg" }) { ...ImageMain }
    results: postgres {
      noses: allNosesList(
        filter: { genders: { contains: [ $genderId ] }},
        orderBy: NAME_ASC,
      ) {
        ...Nose
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const { noses } = data.results
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = 'Noses'
  const description = `
    Fragrance Designers, also called Noses, or Perfumers, spend many years of
    training to become experts in their field. Many have family backgrounds
    in fragrance, chemistry, and other related topics. While all focusing on
    the same goal, creating amazing ${entity.plural}, each brings their own
    unique personality and attributes into their work.
  `
  const keywords = [page]
  const trail = [
    {
      name: page,
      slug: links.noses,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description,
    image: imageMain.src,
    keywords,
    name: page,
    persons: noses,
    slug: links.noses,
  }

  return (
    <Layout>
      <SEO
        description={description}
        keywords={keywords}
        schema={schema}
        title={page}
      />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <Image
        alt={page}
        credit="noses.jpg"
        fixed={imageMain}
      />

      <Paragraph>
        {description}
      </Paragraph>

      <List>
        {noses.map(nose => (
          <ListItem key={nose.id}>
            <Link href={nose.slug}>
              {nose.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
